import React, { FunctionComponent } from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Pill from "../../components/Pill"
import Container from "../../components/Container"
import { graphql } from "gatsby"
import ActionPanel from "../../components/home/ActionPanel"
import SignUpForm from "../../components/SignUpForm"
import Highlight from "../../components/home/Highlight"
import Grid from "../../components/Grid"
import Faq from "../../components/home/Faq"
import styles from "./index.module.css"

interface QueryResult {
  mdx: {
    frontmatter: {
      title: string
      seo: {
        title: string
        description: string
      }
    }
    body: string
  }
}

interface Props {
  data: QueryResult
}

const AboutUsPage: FunctionComponent<Props> = ({ data }) => {
  const page = data.mdx
  // @ts-ignore
  return (
    <Layout>
      <Seo
        description={page.frontmatter.seo.description}
        pathname="/about-us"
        title={page.frontmatter.seo.title}
      />

      <header>
        <Pill bgClass="bg-green-100" variant="bottom-right">
          <Container style={{maxWidth: '85ch'}}>
            <div className="br-prose py-24">
              <div className="br-col-gap md:grid md:grid-cols-12">
                <div className="md:col-span-12 md:col-start-1 xxl:col-span-9 xxl:col-start-1">
                  <h1 className="mb-8 text-left">{page.frontmatter.title}</h1>
                </div>
              </div>
            </div>
          </Container>
        </Pill>
      </header>
      <section className="py-16 lg:py-24">
        <section className="px-6">
          <Container style={{maxWidth: '90ch'}}>
            <Grid>
              <section className="col-span-12 sm:pb-8 xl:col-span-6 xl:col-start-7 xl:pb-0">
                <p className="mb-12">The average partnership manager earns around £38K per year and spends around 35% of
                  their time on
                  partner discovery. That’s £13,300 and 584 hours spent on discovery per person every year!</p>
                <p className="mb-12">Meanwhile, millions of e-commerce businesses are missing out on the benefits of
                  partnerships
                  altogether because they just don’t know where to start.</p>
                <p className="mb-12">We want to change that. So, we built the world’s most tailored partner discovery
                  engine ever.</p>
                <p className="mb-12">Breezy makes it quick, easy and cost-effective to discover relevant partnership
                  opportunities that
                  can actually pay off – leaving you free to focus on making your partnerships a success.</p>
              </section>
              <section className="col-span-12 text-center row-start-1 mb-16 xl:mb-0 xl:row-start-0 xl:col-span-6 xl:col-start-1 xl:row-start-1 xl:text-left">
                <img alt="Keyword-based search" src="./../images/about-us-main.svg"
                     className="inline-block relative mt-0 xl:mt-12"/>
              </section>
            </Grid>
          </Container>
        </section>
      </section>
      <section>
        <Container style={{maxWidth: '85ch'}}>
          <Grid>
            <section className="col-span-12 sm:pb-8 xl:col-span-6 xl:col-start-1">
              <div
                className=" grid grid-cols-1 md:grid-cols-3 items-center">
                <div className="flex justify-center mb-8 md:block md:mb-0">
                  <img alt="Keyword-based search" src="./../images/lightning.svg"/>
                </div>
                <div className="col-span-2 xl:px-12">
                  <p className="text-3 pr-5 text-center md:text-left">As a growth channel, partnerships now exceeds paid search</p>
                </div>
              </div>
            </section>
            <section className="col-span-12 xl:col-span-6 xl:col-start-7 text-left">
              <div
                className=" grid grid-cols-1 md:grid-cols-3 items-center">
                <div className="flex justify-center mb-8 md:block md:mb-0">
                  <img alt="Keyword-based search" src="./../images/plant-base.svg"/>
                </div>
                <div className="col-span-2 xl:px-12">
                  <p className="text-3 pr-5 text-center md:text-left">High-growth brands are 3x more likely to use partnerships as part of their
                    overall strategy</p>
                </div>
              </div>
            </section>
            <section className="col-span-12 xl:col-span-6 xl:col-start-5 text-left xl: mt-10">
              <div
                className=" grid grid-cols-1 md:grid-cols-3 items-center">
                <div className="flex justify-center mb-8 md:block md:mb-0">
                  <img alt="Keyword-based search" src="./../images/atom.svg"/>
                </div>
                <div className="col-span-2 xl:px-12">
                  <p className="text-3 pr-5 text-center md:text-left">More than 2,000 strategic alliances are formed each year</p>
                </div>
              </div>
            </section>
          </Grid>
        </Container>
      </section>
      <section className="mt-20">
        <Container style={{maxWidth: '85ch'}}>
          <Grid>
            <section className="col-span-12 sm:pb-8 xl:col-span-7 xl:col-start-1 mb-24">
              <h2 className={"font-serif"}>The founders</h2>
              <p>After years of working in partnerships, seasoned entrepreneurs Alex Philips and Alex Polyakov became
                frustrated with the constant time, money and brain-power being sucked into the never-ending hunt for
                partners.</p>
            </section>
          </Grid>
        </Container>
      </section>
      <section className="-mt-4">
        <Pill bgClass="bg-green-100" variant="top-left-with-top-space-rotated">
          <Container style={{maxWidth: '75ch'}}>
            <div className="pt-24">
              <Grid>
                <section className="col-span-12 xl:col-span-5 xl:col-start-1 mb-24 xl:mb-0">
                  <div className="flex justify-center">
                    <div className="relative inline-block mb-16">
                      <img className={styles.avatarBg} alt="avatar bg" src="./../images/avatar-bg-1.svg"/>
                      <img className={styles.avatar} alt="Alex Phillips" src="./../images/alex-phillips.jpg"/>
                    </div>
                  </div>
                  <h3 className="m-0">Alex Phillips</h3>
                  <p className="text-3">Co-founder</p>
                  <p className="text-3 mt-8">After more than 6 years managing affiliate and partnership channels for
                    multiple late-stage scaleups, Alex is passionate about sharing the power of partnerships with every
                    business, manager and founder. After managing partners at Transferwise, he founded affiliate and
                    partnership agency Afill.</p>
                </section>
                <section className="col-span-12 xl:col-span-5 xl:col-start-8">
                  <div className="flex justify-center">
                    <div className="relative inline-block mb-16">
                      <img className={styles.avatarBgSecond} alt="avatar bg" src="./../images/avatar-bg-2.svg"/>
                      <img className={styles.avatar} alt="Alex Polyakov" src="./../images/alex-polyakov.jpg"/>
                    </div>
                  </div>
                  <h3 className="m-0">Alex Polyakov</h3>
                  <p className="text-3">Co-founder</p>
                  <p className="text-3 mt-8">Alex has over 10 years’ experience developing software for the partnerships
                    sector, with previous roles including tracking at Adbloom and AM Navigator. After running an agency
                    focused on building and integrating affiliate and partnership tracking systems, Alex realised that
                    there isn’t currently any technology focused on solving the discovery problem. So, he couldn’t
                    resist
                    rising to the challenge!</p>
                </section>
                <section className="col-span-12 xl:col-start-1">
                  <h3 className="text-center mt-32 mb-12">One day, Alex P. said to (the other) Alex P.: </h3>
                </section>
                <section className="col-span-11 col-end-12 xl:col-span-11 xl:col-start-2 xl:col-end-11">
                  <p
                    className={`${styles.quoteLeft} text-4 xl:text-4 leading-normal px-16 py-10 italic rounded-r-xl rounded-bl-xl`}>Imagine
                    a world where finding your ideal partnership opportunities was way less complicated. What if there
                    was
                    a way to map partnerships everywhere, ingrain best practices and support every partnership type.</p>
                </section>
                <section className="col-span-11 col-start-2 xl:col-start-4 col-end-12">
                  <p
                    className={`${styles.quoteRight} text-4 xl:text-4 leading-normal px-16 py-10 italic rounded-l-xl rounded-tr-xl mt-8`}>A
                    way to enable anyone to build successful partnerships all in one powerful platform?</p>
                </section>
              </Grid>
            </div>
            <h3 className="text-center mt-32 my-32">And the rest was history!</h3>
            <br/><br/>
          </Container>
        </Pill>
      </section>
      <section className="text-center">
        <Container style={{maxWidth: '85ch'}}>
          <img className="inline-block my-24" alt="meditating" src="./../images/meditating.svg"/>
          <h2 className="m-0">To bring our vision to life Breezy will...</h2>
          <p className="mt-20 text-5 xxxl:text-6 font-bold">Do for <Highlight text="Partner Discovery"/> what LinkedIn
            did for employee recruitment.</p>
          <p className="mt-4 text-5 xxxl:text-6 font-bold">Do for <Highlight text="Partnership Management"/> what Slack
            did for team collaboration.</p>
        </Container>
      </section>
      <div className="flex justify-center mt-40 mb-56">
        <div className="w-1/3 h-1 bg-green-500"></div>
      </div>
      <section className="mb-10 xl:mb-24">
        <Container style={{maxWidth: '85ch'}}>
          <Grid className="items-center">
            <section className="col-span-12 sm:pb-8 xl:-mt-4 xl:pb-0 xxxl:col-start-2 text-center xl:text-left">
              <h2 className={"font-serif"}>Who is Breezy for?</h2>
              <p>
                Our customers are a diverse range of partnership managers, marketing managers, CEOs, growth managers and
                founders who are looking to self-grow their business.
              </p>
            </section>
          </Grid>
        </Container>
      </section>
      <section className="mb-10 xl:mb-40">
        <Container style={{maxWidth: '85ch'}}>
          <Grid className="items-center">
            <section className="col-span-12 sm:pb-8 xl:col-span-7 xl:-mt-4 xl:pb-0 xxxl:col-start-2 text-center xl:text-left">
              <h3 className={"font-serif"}>Businesses who manage partnerships in-house</h3>
              <p>
                250k+ businesses use self-service SaaS tools to track their partnership programs. They’re currently
                handling the tedious process of discovery and outreach from scratch. Enter Breezy!
              </p>
            </section>

            <section className="col-span-12 row-start-1 xl:row-start-0 xl:col-span-4 xl:col-start-9 flex justify-center text-center">
              <img src="../../images/Independent.svg" alt="independent" className="w-48 lg:w-56" />
            </section>
          </Grid>
        </Container>
      </section>
      <section className="mb-10 xl:mb-40">
        <Container style={{maxWidth: '85ch'}}>
          <Grid className="items-center">
            <section className="col-span-12 sm:pb-8 xl:col-span-7 xl:col-start-6 xl:-mt-4 xl:pb-0 text-center xl:text-left">
              <h3 className={"font-serif"}>Businesses who feel underserved by networks </h3>
              <p>
                750k+ businesses use affiliate networks. These only cater to a limited portion of (often irrelevant)
                opportunities, so businesses risk losing out on other more relevant prospects. That’s where Breezy comes
                in.
              </p>
            </section>

            <section className="col-span-12 row-start-1 xl:row-start-0 xl:col-span-4 xl:col-start-1 xl:row-start-1 flex justify-center text-center">
              <img src="../../images/On-Network.svg" alt="On Network" className="w-48 lg:w-56"/>
            </section>
          </Grid>
        </Container>
      </section>
      <section>
        <Container style={{maxWidth: '85ch'}}>
          <Grid className="items-center">
            <section className="col-span-12 sm:pb-8 xl:col-span-7 xl:-mt-4 xl:pb-0 xxxl:col-start-2 xl:text-left">
              <h3 className={"font-serif"}>Businesses considering launching a partnership channel</h3>
              <p>
                Millions of e-commerce businesses don’t yet use partnerships to grow: “Is it worth the cost and time
                commitment?” “Are there even relevant partners out there to justify it?”
                Breezy can answer those questions to help you make the call.
              </p>
            </section>

            <section className="col-span-12 row-start-1 xl:row-start-0 xl:col-span-4 xl:col-start-9 flex justify-center text-center">
              <img src="../../images/Newbies.svg" alt="Newbies" className="w-48 lg:w-56"/>
            </section>
          </Grid>
        </Container>
      </section>
      <div className="pt-48 pb-56">
        <ActionPanel>
          <h2 className="mb-6 text-4 md:mb-8 md:text-8">
            Join Breezy
          </h2>
          <p className="mb-10 md:mb-16">
            Say goodbye to your realms of spreadsheets and make Breezy the place you organise, filter and sort your
            partner prospects 10x more efficiently. What are you waiting for?
          </p>
          <div className="max-w-xl mx-auto">
            <SignUpForm/>
          </div>
        </ActionPanel>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutUs {
    mdx(fileAbsolutePath: { regex: "//static/about-us-index/" }) {
      frontmatter {
        title
        seo {
          description
          title
        }
      }
      body
    }
  }
`

export default AboutUsPage
